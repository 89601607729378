/** @jsx jsx */
import { Grid, Flex, Box, Themed, useThemeUI, jsx } from 'theme-ui';
import { Container, CallToAction } from '@sprinklr/shared-lib';
import getIntegrationsForm from '../utils/getIntegrationsForm';
import IntegrationsGraphic from '@sprinklr/shared-lib/assets/svgs/integrations-graphic.svg';

type IntegrationsIndexHeroProps = {
  heroHeadline: string;
  heroCopy: string;
  ctaLabel: string;
  ctaUrl: string;
};

const IntegrationsIndexHero: React.FC<IntegrationsIndexHeroProps> = props => {
  const { heroHeadline, heroCopy, ctaLabel, ctaUrl } = props;
  const { theme } = useThemeUI();
  const formData = getIntegrationsForm();

  return (
    <Box
      sx={{
        background: [
          'linear-gradient(180deg, rgba(9,41,58,1) 0%, rgba(18,111,160,1) 100%)',
          null,
          null,
          'linear-gradient(96.5deg, #000000 -4.96%, #118ACB 102.98%)',
        ],
        mb: [null, null, null, '80px'],
      }}
    >
      <Container sx={{ py: [null, null, null, '40px'] }}>
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
            gridGap: '64px',
            alignItems: 'center',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: ['center', null, null, 'flex-start'],
            }}
          >
            <Themed.h1 sx={{ mb: '32px', color: 'white' }}>
              {heroHeadline}
            </Themed.h1>
            <Themed.p
              sx={{
                mb: '32px',
                textAlign: ['center', null, null, 'left'],
                color: 'white',
              }}
            >
              {heroCopy}
            </Themed.p>
            <CallToAction
              type="BUTTON"
              variant="lightPrimary"
              text={ctaLabel}
              url={ctaUrl}
              action="OPEN_MODAL"
              ctaSx={{
                width: 'fit-content',
              }}
              genModalContent={formData}
            />
          </Flex>
          <Box sx={{ width: '100%', display: ['none', null, null, 'block'] }}>
            <IntegrationsGraphic
              style={{ width: '100%', display: ['none', null, null, 'block'] }}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default IntegrationsIndexHero;
