import { graphql, useStaticQuery } from 'gatsby';
import { resolveLocale } from '@sprinklr/shared-lib/utils';
import { useI18next } from 'gatsby-plugin-react-i18next';

const getIntegrationsForm = () => {
  const { language } = useI18next();
  const nodeLocale = resolveLocale(language, true, 'en');
  const data = useStaticQuery(graphql`
    {
      allContentfulTemplateForm(
        filter: { name: { eq: "Demo Request - Hubspot Form" } }
      ) {
        nodes {
          ...ContentfulTemplateFormNode
        }
      }
    }
  `);

  const formData = data?.allContentfulTemplateForm?.nodes?.find(
    form => form.node_locale === nodeLocale,
  );
  return formData;
};
export default getIntegrationsForm;
