/** @jsx jsx */
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Themed, Flex, Box, jsx } from 'theme-ui';
import { buttonReset, sharedButtonStyles } from '@sprinklr/shared-lib/components/button/styles';
import MultiFilterIndicator from './MutliFilterIndicator';
import { MobileFilterWrapperProps } from '../types';

const MobileFilterWrapper: React.FC<MobileFilterWrapperProps> = props => {
  const { toggleOpen, handleRemoveFilter, filteredPostCount, takeoverOpenCta, sortedBy, filterProps, handleFilterSubmit } = props;
  const { filterSets, handleResetClick, enableMultiFilterIndicator, shouldEnableApplyButton } = filterProps;

  const { t } = useTranslation();

  return (
    <Box>
      <Flex
        sx={{
          alignItems: [filteredPostCount ? 'flex-start' : 'flex-end', 'center'],
          flexDirection: ['column', 'row'],
          justifyContent: ['flex-start', filteredPostCount ? 'space-between' : 'flex-end'],
          display: [null, null, null, 'none'],
        }}
      >
        <Flex sx={{ button: { mb: [3, 0], ':not(:last-of-type)': { mr: '12px' } } }}>
          {shouldEnableApplyButton && (
            <Button onClick={handleFilterSubmit} sx={{ ...buttonReset, ...sharedButtonStyles, variant: 'buttons.primarySmall' }}>
              Apply Filters
            </Button>
          )}
          <Button
            onClick={() => toggleOpen(true)}
            sx={{ ...buttonReset, ...sharedButtonStyles, variant: handleRemoveFilter ? 'buttons.primarySmall' : 'buttons.secondarySmall' }}
          >
            {takeoverOpenCta}
          </Button>
          {handleRemoveFilter && (
            <Button type="button" onClick={handleRemoveFilter} sx={{ ...buttonReset, ...sharedButtonStyles, variant: 'buttons.secondarySmall' }}>
              {t('remove filter')}
            </Button>
          )}
        </Flex>
        {filteredPostCount && (
          <Themed.p sx={{ fontWeight: 'semiBold', color: 'mediumGrey', fontSize: [1, 1, 1], mb: [0] }}>
            {filteredPostCount} {t('topics found, sorted by')} {t(sortedBy)}.
          </Themed.p>
        )}
      </Flex>
      {enableMultiFilterIndicator && (
        <MultiFilterIndicator
          filterSets={filterSets}
          multiFilterIndicatorSx={{ display: ['flex', null, null, 'none'] }}
          handleResetClick={handleResetClick}
        />
      )}
    </Box>
  );
};

export default MobileFilterWrapper;
