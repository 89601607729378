/** @jsx jsx */
import { useState, useEffect, useRef } from 'react';
import { Box, Flex, jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import FilterCheckbox from './FilterCheckbox';
import FilterSetLabel from './FilterSetLabel';
import { filterSetContainerStyles, setCollapsibleFilterSetStyles } from '../styles';
import { FilterSetProps } from '../types';

const FilterSet: React.FC<FilterSetProps> = ({ label, filters, isCollapsible, isInitiallyCollapsed, allFilter, setAllFilter }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(!isInitiallyCollapsed);
  const [filterContainerHeight, setFilterContainerHeight] = useState('100%');

  const handleCollapseClick = e => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const filterContainerRef = useRef(null);
  if (isCollapsible) {
    useEffect(() => {
      setTimeout(() => {
        setFilterContainerHeight(`${isExpanded ? filterContainerRef?.current?.scrollHeight : 0}px`);
      }, 200);
    }, [isExpanded]);
  }

  const collapsibleStyles = setCollapsibleFilterSetStyles(isCollapsible, isExpanded, filterContainerHeight);

  return (
    <Box pb={4}>
      <FilterSetLabel isCollapsible={isCollapsible} handleCollapseClick={handleCollapseClick} isExpanded={isExpanded} label={label} />
      <Flex
        ref={filterContainerRef}
        sx={{
          ...filterSetContainerStyles,
          ...collapsibleStyles,
        }}
      >
        {filters.map((filter, i) => {
          return (
            <FilterCheckbox
              key={`${filter?.label} + ${i}`}
              label={t(filter?.label)}
              filter={filter?.filter}
              value={filter?.value}
              setFilter={filter?.setFilter}
              allFilter={allFilter}
              setAllFilter={setAllFilter}
            />
          );
        })}
      </Flex>
    </Box>
  );
};

export default FilterSet;
