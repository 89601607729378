/** @jsx jsx */
import { useEffect } from 'react';
import ReactDom from 'react-dom';
import { Flex, Grid, jsx, Themed } from 'theme-ui';
import MultiFilter from './MultiFilter';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import CloseIcon from '@sprinklr/shared-lib/assets/svgs/close.svg';
import { FilterTakeoverProps } from './types';
import { trapKeyboardFocus } from '@sprinklr/shared-lib/utils/trapKeyboardFocus';

const FilterTakeover: React.FC<FilterTakeoverProps> = props => {
  const { takeoverTitle, isOpen, toggleOpen, handleFilterSubmit, filterProps } = props;

  const filterPortal = document.createElement('div');
  filterPortal.setAttribute('id', 'filter-portal');

  isOpen && document?.body?.appendChild(filterPortal);
  !isOpen && document?.getElementById('filter-portal')?.remove();

  window.onkeydown = function (e) {
    e.key === 'Escape' && toggleOpen(false);
  };

  useEffect(() => {
    trapKeyboardFocus('filter-portal', 1);
  });

  if (!isOpen) return null;
  return ReactDom.createPortal(
    <Flex
      id="filter-modal"
      sx={{
        flexDirection: 'column',
        position: 'fixed',
        width: '100vw',
        height: '100%',
        top: ['66px', '96px'],
        left: 0,
        right: 0,
        zIndex: 30,
        background: '#fff',
        borderTop: '1px solid #EEEEEE',
      }}
    >
      <Grid sx={{ gridTemplateColumns: '24px 1fr 24px', px: ['16px', '80px'] }}>
        <Themed.h6 sx={{ gridColumn: 2, mb: 0, justifySelf: 'center', py: '16px' }}>{takeoverTitle}</Themed.h6>
        <button
          onClick={() => toggleOpen(false)}
          sx={{ ...buttonReset, width: '24px', height: ['44px', null, null, '24px'], alignSelf: 'center', gridColumn: 3 }}
        >
          <CloseIcon sx={{ height: '12px', width: '12px', fill: 'darkGrey' }} />
        </button>
      </Grid>
      <MultiFilter isFilterTakeover={true} handleFilterSubmit={handleFilterSubmit} {...filterProps} />
    </Flex>,
    document.getElementById('filter-portal')
  );
};

export default FilterTakeover;
