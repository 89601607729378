import Check from '@sprinklr/shared-lib/assets/images/check.png';

export const setFilterTakeoverStyles = isFilterTakeover => {
  if (isFilterTakeover) {
    return {
      form: {
        borderTop: '1px solid #EEEEEE',
        // Magic numbers for header + subheader
        height: ['calc(100% - 122px)', 'calc(100% - 154px)'],
      },
      fieldContainer: {
        flexGrow: '1',
        pt: '16px',
        px: ['16px', '80px'],
        overflow: 'scroll',
      },
      buttonContainer: {
        gridGap: '16px',
        borderTop: '1px solid #EEEEEE',
        button: { my: '16px' },
        px: ['16px', '80px'],
      },
    };
  }
  return null;
};

export const filterSetContainerStyles = {
  flexDirection: 'column',
  label: {
    mb: 1,
    fontWeight: 'semiBold',
    fontSize: 1,
    paddingLeft: '32px',
    textIndent: '-32px',
  },
  'input[type=checkbox]': {
    margin: 0,
    marginRight: '12px',
    width: '20px !important',
    height: '20px',
    appearance: 'none',
    backgroundColor: 'formFieldGrey',
    borderRadius: '4px',
    position: 'relative',
    top: '5px',
    border: 0,
    '&:focus-visible': {
      outlineColor: 'primary',
    },
  },
  'input[type=checkbox]:checked': {
    backgroundColor: 'secondary',
    backgroundImage: `url(${Check})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '12px 9px',
    backgroundPosition: 'center',
    backgroundClip: 'border-box',
  },
};

export const setCollapsibleFilterSetStyles = (isCollapsible, isExpanded, filterContainerHeight) => {
  if (isCollapsible)
    return {
      maxHeight: isExpanded ? `${filterContainerHeight}` : 0,
      opacity: isExpanded ? 1 : 0,
      overflow: 'hidden',
      transition: 'max-height 0.2s linear, opacity .2s linear',
    };
  return null;
};
