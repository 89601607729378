/** @jsx jsx */
import { jsx, Label } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ArrowDown from '@sprinklr/shared-lib/assets/svgs/chevron.svg';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';
import { FilterSetLabelProps } from '../types';

const FilterSetLabel: React.FC<FilterSetLabelProps> = props => {
  const { isCollapsible, handleCollapseClick, isExpanded, label } = props;
  const { t } = useTranslation();

  if (isCollapsible)
    return (
      <button
        onClick={handleCollapseClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          mb: 2,
          ':hover, label:hover': { cursor: 'pointer', path: { fill: 'rgb(24, 90, 200)' } },
          svg: { transform: isExpanded && 'rotate(180deg)', width: '14px', path: { fill: isExpanded ? '#000' : 'rgb(153, 153, 153)' } },
          ...buttonReset,
        }}
      >
        <Label>{t(label)}:</Label>
        {isCollapsible && <ArrowDown />}
      </button>
    );
  return <Label sx={{ mb: 2 }}>{t(label)}:</Label>;
};

export default FilterSetLabel;
