export const trapKeyboardFocus = (id, initialFocusableEl = 0) => {
  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
  const modal = document?.getElementById(id);

  const focusableContent = modal?.querySelectorAll(focusableElements);
  const firstFocusableElement = modal?.querySelectorAll(focusableElements)[0];
  const initFocusableElement =
    modal?.querySelectorAll(focusableElements)[initialFocusableEl];
  const lastFocusableElement =
    focusableContent && focusableContent[focusableContent?.length - 1];

  // Optional control over first element to focus.
  // For example, close button may be first el. so init focus on second element by passing 1
  initFocusableElement?.focus();

  document?.addEventListener('keydown', e => {
    const isTabPressed = e.key === 'Tab';

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) {
      // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement?.focus();
        e.preventDefault();
      }
    } else {
      // if tab key is pressed
      if (document.activeElement === lastFocusableElement) {
        // if focused has reached to last focusable element then focus first focusable element after pressing tab
        firstFocusableElement?.focus();
        e.preventDefault();
      }
    }
  });
};

// NOTE: Must wrap this function in useEffect hook in the component which it is invoked
// Inspiration: https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
