/** @jsx jsx */
import React from 'react';
import { Box, Grid, jsx, Button } from 'theme-ui';
import FilterSet from './components/FilterSet';
import Dropdown from '../Dropdown';
import { buttonReset, sharedButtonStyles } from '@sprinklr/shared-lib/components/button/styles';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { MultiFilterProps } from './types';
import { setFilterTakeoverStyles } from './styles';
import MultiFilterIndicator from './components/MutliFilterIndicator';

// TODO: Rename file and folders to avoid confusion with multiFilter util
const MultiFilter: React.FC<MultiFilterProps> = props => {
  const {
    handleFilterSubmit,
    sortType,
    setSortType,
    allFilter,
    setAllFilter,
    handleResetClick,
    filterSets,
    sortOptions,
    customSortLabel,
    customApplyFilterLabel,
    isFilterTakeover,
    shouldHideResponsive,
    shouldEnableApplyButton = true,
    resetButtonAsText,
    enableMultiFilterIndicator,
  } = props;
  const { t } = useTranslation();

  const filterTakeoverStyles = setFilterTakeoverStyles(isFilterTakeover);

  // Need to conditionally set display: none, because useBpIndex fires after load and causes jank
  const displayStyles = shouldHideResponsive ? ['none', 'none', 'none', 'flex'] : 'flex';

  return (
    <form sx={{ display: displayStyles, flexDirection: 'column', ...filterTakeoverStyles?.form }} onSubmit={handleFilterSubmit}>
      <Box sx={filterTakeoverStyles?.fieldContainer}>
        {sortOptions && (
          <Dropdown
            value={sortType}
            setValue={setSortType}
            options={sortOptions}
            customDropdownLabel={customSortLabel}
            dropdownSx={{ maxWidth: [null, '50%', null, '100%'] }}
          />
        )}
        {filterSets &&
          filterSets.map(filterSet => (
            <FilterSet
              key={filterSet?.label}
              label={filterSet?.label}
              filters={filterSet?.filters}
              isCollapsible={filterSet?.isCollapsible}
              isInitiallyCollapsed={filterSet?.isInitiallyCollapsed}
              allFilter={allFilter}
              setAllFilter={setAllFilter}
            />
          ))}
      </Box>
      {enableMultiFilterIndicator && (
        <MultiFilterIndicator
          filterSets={filterSets}
          handleResetClick={handleResetClick}
          multiFilterIndicatorSx={{ display: ['none', null, null, 'flex'] }}
        />
      )}
      <Grid
        sx={{
          gridTemplateColumns: ['1fr 1fr', null, null, '1fr'],
          ...filterTakeoverStyles?.buttonContainer,
        }}
      >
        <Button
          type="submit"
          disabled={!shouldEnableApplyButton}
          sx={{
            ...buttonReset,
            ...sharedButtonStyles,
            width: '100%',
            variant: 'buttons.primarySmall',
            ':disabled': {
              backgroundColor: 'disabled',
              cursor: 'default',
            },
          }}
        >
          {t(customApplyFilterLabel || 'Apply Filter')}
        </Button>
        {(!resetButtonAsText || isFilterTakeover) && (
          <Button
            type="button"
            onClick={() => handleResetClick()}
            sx={{ ...buttonReset, ...sharedButtonStyles, width: '100%', variant: 'buttons.secondarySmall' }}
          >
            {t('Clear all filters')}
          </Button>
        )}
      </Grid>
    </form>
  );
};

export default MultiFilter;
