import { HeadAdditions, Layout, SEO } from '@sprinklr/shared-lib';
import GradientBackground from '@sprinklrjs/website-components/components/gradientBackground';
import React, { ReactElement } from 'react';
import { Box, Flex } from 'theme-ui';
import { useAnchorTagListener } from '../../../utils/useAnchorTagListener';
import FooterV3 from '../../templates/footerV3';
import HeaderV3 from '../../templates/headerV3';
import HeaderV2 from '../../templates/headerV2';
import Footer from '@sprinklr/shared-lib/templates/footerTemplate';
import SocialLinksTemplate from '../../../src/templates/socialLinksTemplate';

export default function HardcodedPagesLayout({
  children,
  slug,
  context,
  site,
  metadata,
  headerV3,
  footerV3,
  globalHeaderFooterData,
  pageData,
}): ReactElement {
  useAnchorTagListener();

  const { header, footer, social } = globalHeaderFooterData ?? {};

  return (
    <Layout context={context} path={slug}>
      <GradientBackground
        isGradientDisabled={context?.isGradientBackgroundEnabled}
      >
        <HeadAdditions site={site} />
        <SEO
          {...metadata}
          slug={slug}
          translations={context?.translations}
          keywords={metadata?.seoKeywords}
          description={metadata?.seoDescription}
          seoDescription={metadata?.seoDescription}
          title={metadata?.seoTitle}
          site={site}
          domain={'https://www.sprinklr.com'}
          isNoIndex={metadata?.isNoIndex || context?.isNoIndex}
          openGraphDescription={metadata?.openGraphDescription}
          twitterCardDescription={metadata?.twitterCardDescription}
          resolvedPath={context?.resolvedPathLocale}
        />
        <Flex sx={{ height: '100%', flexDirection: 'column' }}>
          <Box sx={{ flex: '1 0 auto' }}>
            {headerV3 ? (
              <HeaderV3
                contentfulHeader={headerV3}
                isShadowEnabled
                translations={context?.translations}
                contentfulPage={pageData}
              />
            ) : null}
            {header ? (
              <HeaderV2
                contentfulHeader={header}
                isShadowEnabled
                pageContext={context}
                site={site}
                translations={context?.translations}
                contentfulPage={pageData}
                isCtasHidden={false}
              />
            ) : null}
            <Box as="main" id="content">
              {children}
            </Box>
          </Box>
          {social ? (
            <SocialLinksTemplate {...social} rootSx={{ pt: 0 }} />
          ) : null}
          {footer ? <Footer {...footer} /> : null}
          {footerV3 ? <FooterV3 contentfulFooter={footerV3} /> : null}
        </Flex>
      </GradientBackground>
    </Layout>
  );
}
