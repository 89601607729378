/** @jsx jsx */
import React, { useState, Fragment } from 'react';
import { Grid, Box, Themed, jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, StickyElement } from '@sprinklr/shared-lib';
import IntegrationsCard from './IntegrationsCard';
import { IntegrationsIndexColumnsProps } from '../types';
import IntegrationsFilter from './IntegrationsFilter';

const IntegrationsIndexColumns: React.FC<
  IntegrationsIndexColumnsProps
> = props => {
  const { integrationsCards } = props;
  //Gatsby doesn't handle the name go.usa correctly when it sorts, so we need to sort the results again.
  integrationsCards.forEach(
    card => (card.sortName = card.integrationName.replace('.', ' ')),
  );
  const alphabetizedCards = integrationsCards.sort((a, b) =>
    a?.sortName.localeCompare(b?.sortName),
  );

  const [filteredCards, setFilteredCards] = useState(alphabetizedCards);

  const { t } = useTranslation();

  const filterProps = {
    alphabetizedCards,
    setFilteredCards,
  };

  return (
    <Container>
      <Grid
        sx={{
          height: '100%',
          gridTemplateColumns: ['1fr', null, null, '1fr 250px'],
          columnGap: '100px',
          rowGap: [4, '40px'],
        }}
      >
        <Box
          sx={{
            minWidth: [null, null, null, '250px'],
            order: [null, null, null, 2],
          }}
        >
          {/* removing StickyElement allows this to render before the fonts load in Filterset.tsx which causes visual bug. Instead we set it to `static` */}
          <StickyElement stickyElementSx={{ position: 'static' }}>
            <IntegrationsFilter {...filterProps} />
          </StickyElement>
        </Box>
        <Box>
          {filteredCards?.length > 0 ? (
            <Grid
              sx={{
                gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
                gridGap: '32px',
                gridAutoRows: '1fr',
              }}
            >
              {filteredCards?.map((cardData, i) => (
                <IntegrationsCard
                  {...cardData}
                  key={`${cardData.integrationName}-${i}`}
                />
              ))}
            </Grid>
          ) : (
            <Fragment>
              <Themed.h4>
                {t(
                  'We don’t have any matches for your current search filters.',
                )}
              </Themed.h4>
              <Themed.p>
                {t(
                  'To explore our available platform integrations, please change or clear your filters.',
                )}
              </Themed.p>
            </Fragment>
          )}
        </Box>
      </Grid>
    </Container>
  );
};

export default IntegrationsIndexColumns;
