/** @jsx jsx */
import { Grid, Box, Flex, Themed, jsx } from 'theme-ui';
import { ImageBlock } from '@sprinklr/shared-lib';
import { IntegrationsCardProps } from '../types';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const IntegrationsCard: React.FC<IntegrationsCardProps> = props => {
  const {
    integrationDescription,
    integrationLogo,
    integrationName,
    relatedCategories,
  } = props;
  const { t } = useTranslation();

  return (
    <Box sx={{ borderRadius: '20px', boxShadow: 3, py: '24px', px: '32px' }}>
      <Grid
        sx={{
          gridTemplateColumns: '64px 1fr',
          gridColumnGap: '20px',
          alignItems: 'center',
          minHeight: '64px',
          mb: '16px',
        }}
      >
        <ImageBlock image={integrationLogo} />
        <Themed.h3 sx={{ mb: 0, fontSize: [5, 5, 5, 5], lineHeight: '29px' }}>
          {integrationName}
        </Themed.h3>
      </Grid>
      <Flex sx={{ flexWrap: 'wrap', mb: '16px' }}>
        {relatedCategories?.map((cat, i) => {
          const translatedCategory = t(cat);
          return (
            <Themed.p
              key={`${cat}-${i}`}
              sx={{
                backgroundColor: 'formFieldGrey',
                color: 'darkGrey',
                fontSize: ['12px', '12px', '12px', '12px'],
                fontWeight: 'semiBold',
                padding: '4px 8px',
                borderRadius: '4px',
                mb: '8px',
                mr: '8px',
              }}
            >
              {translatedCategory}
            </Themed.p>
          );
        })}
      </Flex>
      <Box>
        <Themed.p
          sx={{
            color: 'darkGrey',
            fontSize: ['16px', '16px', '16px', '16px'],
            lineHeight: '26px',
          }}
        >
          {integrationDescription}
        </Themed.p>
      </Box>
    </Box>
  );
};

export default IntegrationsCard;
