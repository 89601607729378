/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { FilterCheckboxProps } from '../types';

const FilterCheckbox: React.FC<FilterCheckboxProps> = ({ label, filter, setFilter, allFilter, setAllFilter, value }) => {
  const { t } = useTranslation();
  const isAllFilter = label === 'All';

  return (
    <label>
      <input
        type="checkbox"
        checked={isAllFilter ? filter : !allFilter && filter}
        onChange={() => {
          setFilter(!filter, value);
          !isAllFilter && setAllFilter && setAllFilter(false);
        }}
      />
      {t(label)}
    </label>
  );
};

export default FilterCheckbox;
