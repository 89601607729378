/** @jsx jsx */
import React from 'react';
import { Flex, Box, jsx } from 'theme-ui';
import XIcon from '@sprinklr/shared-lib/assets/svgs/xIcon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { buttonReset } from '@sprinklr/shared-lib/components/button/styles';

const MultiFilterIndicator = props => {
  const { filterSets, multiFilterIndicatorSx, handleResetClick } = props;
  const { t } = useTranslation();

  const filterSetReducer = array =>
    array &&
    array.reduce((acc, curr) => {
      curr.filters.forEach(el => el.filter && acc.push(el));
      return acc;
    }, []);
  const displayFilters = filterSetReducer(filterSets);

  const hasFiltersSelected = displayFilters.length > 0;

  return (
    <Flex sx={{ pb: hasFiltersSelected && '32px', pt: hasFiltersSelected && '20px', width: '100%', flexWrap: 'wrap', ...multiFilterIndicatorSx }}>
      {displayFilters &&
        displayFilters.map(filter => {
          const handleClick = e => {
            e.preventDefault;
            filter.setFilter(!filter, filter.label);
          };
          return (
            <button
              onClick={handleClick}
              key={filter.label}
              sx={{
                ...buttonReset,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'formFieldGrey',
                color: 'darkGrey',
                pl: '12px',
                pr: 2,
                mr: 2,
                mb: 2,
                height: '24px',
                borderRadius: '12px',
                border: '1px solid',
                borderColor: 'transparent',
                p: {
                  fontWeight: 600,
                  fontSize: [0, 0, 0, 0],
                  lineHeight: ['15px', '15px', '15px', '15px'],
                  my: 0,
                },
                svg: {
                  position: 'relative',
                  bottom: '1px',
                  mr: 1,
                  fill: 'darkGrey',
                },
                '&:hover': {
                  transition: '.2s background-color, .2s border-color',
                  color: 'secondary',
                  borderColor: 'secondary',
                  backgroundColor: 'transparent',
                  svg: {
                    fill: 'secondary',
                  },
                },
              }}
            >
              <XIcon />
              <p>{t(filter.label)}</p>
            </button>
          );
        })}
      {hasFiltersSelected && (
        <Flex sx={{ alignItems: 'center', mb: 2 }}>
          <button
            type="button"
            onClick={() => handleResetClick()}
            sx={{ ...buttonReset, fontSize: [1, 1, 1, 1], color: 'primary', textDecoration: 'underline', fontWeight: 'bold' }}
          >
            {t('Clear all filters')}
          </button>
        </Flex>
      )}
    </Flex>
  );
};
export default MultiFilterIndicator;
