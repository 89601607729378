export const defaultState = {
  care: false,
  research: false,
  marketing: false,
  sales: false,
  cxm: false,
  analytics: false,
  attribution: false,
  ccaas: false,
  channelReviewSite: false,
  collaboration: false,
  community: false,
  compliance: false,
  contentManagement: false,
  contentMarketing: false,
  customerFeedback: false,
  conversationalAI: false,
  crmTools: false,
  dataSource: false,
  dataVisualization: false,
  digitalAssetManagement: false,
  knowledgebase: false,
  marketingAutomation: false,
  reviews: false,
  urlShorteners: false,
};

export const filterLookup = {
  'Sprinklr Service': 'care',
  'Sprinklr Insights': 'research',
  'Sprinklr Marketing': 'marketing',
  'Sprinklr Social': 'sales',
  'Unified-CXM Platform': 'cxm',
  'Analytics Tools': 'analytics',
  'Archive and Compliance': 'compliance',
  'Attribution Tools': 'attribution',
  CCaaS: 'ccaas',
  'Channel / Review Site': 'channelReviewSite',
  Collaboration: 'collaboration',
  Community: 'community',
  'Content Management': 'contentManagement',
  'Content Marketing Tools': 'contentMarketing',
  'Customer Feedback': 'customerFeedback',
  'Conversational AI': 'conversationalAI',
  CRM: 'crmTools',
  'Data Source': 'dataSource',
  'Data Visualization': 'dataVisualization',
  'Digital Asset Management': 'digitalAssetManagement',
  'Knowledge Base Recommendations': 'knowledgebase',
  'Marketing Automation': 'marketingAutomation',
  'Ratings & Reviews': 'reviews',
  'URL Shorteners': 'urlShorteners',
};
export const paramsLookup = {
  care: 'care',
  research: 'research',
  marketing: 'marketing',
  sales: 'sales',
  cxm: 'cxm',
  analytics: 'analytics',
  attribution: 'attribution',
  ccaas: 'ccaas',
  channel: 'channelReviewSite',
  collaboration: 'collaboration',
  community: 'community',
  compliance: 'compliance',
  'content-management': 'contentManagement',
  'content-marketing': 'contentMarketing',
  'customer-feedback': 'customerFeedback',
  'conversational-ai': 'conversationalAI',
  crm: 'crmTools',
  'data-source': 'dataSource',
  'data-visualization': 'dataVisualization',
  'digital-asset-management': 'digitalAssetManagement',
  knowledgebase: 'knowledgebase',
  'marketing-automation': 'marketingAutomation',
  reviews: 'reviews',
  'url-shorteners': 'urlShorteners',
};

export const paramsReverseLookup = {
  care: 'care',
  research: 'research',
  marketing: 'marketing',
  sales: 'sales',
  cxm: 'cxm',
  analytics: 'analytics',
  attribution: 'attribution',
  ccaas: 'ccaas',
  channel: 'channelReviewSite',
  collaboration: 'collaboration',
  community: 'community',
  compliance: 'compliance',
  contentManagement: 'content-management',
  contentMarketing: 'content-marketing',
  customerFeedback: 'customer-feedback',
  conversationalAI: 'conversational-ai',
  crmTools: 'crm',
  dataSource: 'data-source',
  dataVisualization: 'data-visualization',
  digitalAssetManagement: 'digital-asset-management',
  knowledgebase: 'knowledgebase',
  marketingAutomation: 'marketing-automation',
  reviews: 'reviews',
  urlShorteners: 'url-shorteners',
};
export const setShouldFilterByCategory = filters =>
  filters.cxm ||
  filters.analytics ||
  filters.attribution ||
  filters.ccaas ||
  filters.channelReviewSite ||
  filters.collaboration ||
  filters.community ||
  filters.compliance ||
  filters.contentManagement ||
  filters.contentMarketing ||
  filters.customerFeedback ||
  filters.conversationalAI ||
  filters.crmTools ||
  filters.dataSource ||
  filters.dataVisualization ||
  filters.digitalAssetManagement ||
  filters.knowledgebase ||
  filters.marketingAutomation ||
  filters.reviews ||
  filters.urlShorteners;

export const setshouldFilterByProduct = filters => filters.care || filters.research || filters.marketing || filters.sales;

export const setProductCheckboxes = (filters, checkboxToggler) => [
  {
    filter: filters.care,
    label: 'Sprinklr Service',
    value: 'Sprinklr Service',
    setFilter: checkboxToggler,
  },
  {
    filter: filters.research,
    label: 'Sprinklr Insights',
    value: 'Sprinklr Insights',
    setFilter: checkboxToggler,
  },
  {
    filter: filters.marketing,
    label: 'Sprinklr Marketing',
    value: 'Sprinklr Marketing',
    setFilter: checkboxToggler,
  },
  {
    filter: filters.sales,
    label: 'Sprinklr Social',
    value: 'Sprinklr Social',
    setFilter: checkboxToggler,
  },
];

export const setCategoryCheckboxes = (filters, checkboxToggler) => [
  {
    label: 'Analytics Tools',
    value: 'Analytics Tools',
    filter: filters.analytics,
    setFilter: checkboxToggler,
  },
  {
    label: 'Archive and Compliance',
    value: 'Archive and Compliance',
    filter: filters.compliance,
    setFilter: checkboxToggler,
  },
  {
    label: 'Attribution Tools',
    value: 'Attribution Tools',
    filter: filters.attribution,
    setFilter: checkboxToggler,
  },
  // {
  //   label: 'Channel / Review Site',
  //   filter: filters.channelReviewSite,
  //   setFilter: checkboxToggler,
  // },
  // {
  //   label: 'Collaboration',
  //   filter: filters.collaboration,
  //   setFilter: checkboxToggler,
  // },
  {
    label: 'CCaaS',
    value: 'CCaaS',
    filter: filters.ccaas,
    setFilter: checkboxToggler,
  },
  {
    label: 'Community',
    value: 'Community',
    filter: filters.community,
    setFilter: checkboxToggler,
  },
  {
    label: 'Content Management',
    value: 'Content Management',
    filter: filters.contentManagement,
    setFilter: checkboxToggler,
  },
  {
    label: 'Content Marketing Tools',
    value: 'Content Marketing Tools',
    filter: filters.contentMarketing,
    setFilter: checkboxToggler,
  },
  {
    label: 'Customer Feedback',
    value: 'Customer Feedback',
    filter: filters.customerFeedback,
    setFilter: checkboxToggler,
  },
  {
    label: 'Conversational AI',
    value: 'Conversational AI',
    filter: filters.conversationalAI,
    setFilter: checkboxToggler,
  },
  {
    label: 'CRM',
    value: 'CRM',
    filter: filters.crmTools,
    setFilter: checkboxToggler,
  },
  {
    label: 'Data Source',
    value: 'Data Source',
    filter: filters.dataSource,
    setFilter: checkboxToggler,
  },
  {
    label: 'Data Visualization',
    value: 'Data Visualization',
    filter: filters.dataVisualization,
    setFilter: checkboxToggler,
  },
  {
    label: 'Digital Asset Management',
    value: 'Digital Asset Management',
    filter: filters.digitalAssetManagement,
    setFilter: checkboxToggler,
  },
  {
    label: 'Knowledge Base Recommendations',
    value: 'Knowledge Base Recommendations',
    filter: filters.knowledgebase,
    setFilter: checkboxToggler,
  },
  {
    label: 'Marketing Automation',
    value: 'Marketing Automation',
    filter: filters.marketingAutomation,
    setFilter: checkboxToggler,
  },
  {
    label: 'Ratings & Reviews',
    value: 'Ratings & Reviews',
    filter: filters.reviews,
    setFilter: checkboxToggler,
  },
  {
    label: 'URL Shorteners',
    value: 'URL Shorteners',
    filter: filters.urlShorteners,
    setFilter: checkboxToggler,
  },
];

export const getTrueKeys = filters => {
  const trueFilters = Object.entries(filters).filter(element => element !== undefined && element[1] === true);
  const trueKeys = trueFilters.map(entry => entry[0]);
  return trueKeys;
};
