/** @jsx jsx */
import { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { MultiFilterTemplate } from '@sprinklr/shared-lib/components/multiFilter';
import {
  defaultState,
  filterLookup,
  paramsLookup,
  paramsReverseLookup,
  setCategoryCheckboxes,
  setProductCheckboxes,
  setShouldFilterByCategory,
  setshouldFilterByProduct,
  getTrueKeys,
} from '../utils/integrationsFilterUtils';

const IntegrationsFilter = props => {
  const { alphabetizedCards, setFilteredCards } = props;

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filters, setFilters] = useState({ ...defaultState });
  const [currentlyAppliedFilters, setCurrentlyAppliedFilters] = useState({
    ...defaultState,
  });
  const [shouldEnableApplyButton, setShouldEnableApplyButton] = useState(false);

  //get params on load
  const searchParams =
    typeof window !== 'undefined' &&
    new URLSearchParams(window.location.search);
  const searchParamsFilters = searchParams && searchParams.getAll('filters');
  const searchParamsArray = searchParamsFilters[0]?.split(' ');

  //set filters according to params
  useEffect(() => {
    searchParamsArray &&
      searchParamsArray.forEach(element => {
        setFilters(prevState => ({
          ...prevState,
          [paramsLookup[element]]: true,
        }));
        setCurrentlyAppliedFilters(prevState => ({
          ...prevState,
          [paramsLookup[element]]: true,
        }));
        const trueKeys = getTrueKeys(filters);
        setFilteredCards(filterCards(alphabetizedCards, trueKeys));
      });
  }, [isInitialLoad]);

  //util for checkboxes to pass up state
  const checkboxToggler = (isChecked, label) => {
    setFilters(prevState => ({
      ...prevState,
      [filterLookup[label]]: isChecked,
    }));
  };

  //control button active state
  useEffect(() => {
    const buttonShouldBeEnabled =
      JSON.stringify(filters) !== JSON.stringify(currentlyAppliedFilters);
    setShouldEnableApplyButton(buttonShouldBeEnabled);
  }, [filters]);

  //get data from utils
  const productCheckboxes = setProductCheckboxes(filters, checkboxToggler);
  const categoryCheckboxes = setCategoryCheckboxes(filters, checkboxToggler);
  const shouldFilterByCategory = setShouldFilterByCategory(filters);
  const shouldFilterByProduct = setshouldFilterByProduct(filters);

  //group filters to feed to Multifilter
  const filterSets = [
    { label: 'Product', filters: productCheckboxes, isCollapsible: true },
    {
      label: 'Category',
      filters: categoryCheckboxes,
      isCollapsible: true,
      isInitiallyCollapsed: true,
    },
  ];

  const handleFilterSubmit = e => {
    e.preventDefault();
    const trueKeys = getTrueKeys(filters);
    setCurrentlyAppliedFilters(filters);
    setFilteredCards(filterCards(alphabetizedCards, trueKeys));
    setShouldEnableApplyButton(false);
    handleNavigate(trueKeys);
  };

  const handleResetClick = () => {
    setFilters({ ...defaultState });
    setShouldEnableApplyButton(false);
  };

  const handleNavigate = trueKeys => {
    const paramsFromKeys = trueKeys.map(
      element => paramsReverseLookup[element],
    );
    const url = new URL(window.location.toString());
    const pathName = window.location.pathname;
    url.pathname = pathName;
    url.searchParams.delete('filters');
    paramsFromKeys[0] &&
      url.searchParams.set('filters', paramsFromKeys.join(' '));
    window.history.pushState({}, '', url.pathname + url.search);
  };

  const filterCards = (cards, trueKeys) => {
    const categoryReduce = array =>
      array.reduce((acc, cur) => {
        let included = false;
        cur.relatedCategories?.forEach(element => {
          if (trueKeys.includes(filterLookup[element]) && !included) {
            acc.push(cur);
            included = true;
          }
        });

        return acc;
      }, []);

    const productReduce = array =>
      array.reduce((acc, cur) => {
        let included = false;
        cur.relatedProducts.forEach(element => {
          if (trueKeys.includes(filterLookup[element]) && !included) {
            acc.push(cur);
            included = true;
          }
        });
        return acc;
      }, []);

    const doubleReducer = array => {
      const categoryFiltered = shouldFilterByCategory
        ? categoryReduce(array)
        : array;
      const productFiltered = shouldFilterByProduct
        ? productReduce(categoryFiltered)
        : categoryFiltered;
      return productFiltered;
    };
    setIsInitialLoad(false);
    return doubleReducer(cards);
  };

  const filterProps = {
    filterSets,
    handleResetClick,
    shouldEnableApplyButton,
    resetButtonAsText: true,
    enableMultiFilterIndicator: true,
  };

  return (
    <MultiFilterTemplate
      handleFilterSubmit={handleFilterSubmit}
      filterProps={filterProps}
    />
  );
};

export default IntegrationsFilter;
