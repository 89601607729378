/** @jsx jsx */
import React, { useState, useEffect, useCallback } from 'react';
import { jsx } from 'theme-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useBreakpointIndex } from '@theme-ui/match-media';
import FilterTakeover from './FilterTakeover';
import MultiFilter from './MultiFilter';
import MobileFilterWrapper from './components/MobileFilterWrapper';
import { MultiFilterTemplateProps } from './types';

const MultiFilterTemplate: React.FC<MultiFilterTemplateProps> = props => {
  const { handleRemoveFilter, handleFilterSubmit, filteredPostCount, sortedBy, filterProps } = props;

  const { t } = useTranslation();
  const bpIndex = useBreakpointIndex({ defaultIndex: 3 });

  const [isTakeoverOpen, setIsTakeoverOpen] = useState(false);

  const toggleOpen = useCallback(
    isOpen => {
      setIsTakeoverOpen(isOpen);
    },
    [isTakeoverOpen]
  );

  const handleTakeoverFilterSubmit = e => {
    handleFilterSubmit(e);
    setIsTakeoverOpen(false);
  };

  useEffect(() => {
    if (document) document.body.style.overflow = isTakeoverOpen ? 'hidden' : '';
    bpIndex >= 3 && setIsTakeoverOpen(false);
  }, [isTakeoverOpen, bpIndex]);

  const hasSortOptions = !!filterProps?.sortOptions;
  const hasFilters = !!filterProps?.filterSets;

  let takeoverTitleOpenCta = t('Filter & Sort');
  if (hasFilters && !hasSortOptions) takeoverTitleOpenCta = t('Filter');
  if (hasSortOptions && !hasFilters) takeoverTitleOpenCta = t('Sort');

  return (
    <React.Fragment>
      <MobileFilterWrapper
        toggleOpen={toggleOpen}
        handleRemoveFilter={handleRemoveFilter}
        filteredPostCount={filteredPostCount}
        sortedBy={sortedBy}
        takeoverOpenCta={takeoverTitleOpenCta}
        filterProps={filterProps}
        handleFilterSubmit={handleFilterSubmit}
      />
      <MultiFilter
        shouldHideResponsive={true}
        handleFilterSubmit={handleFilterSubmit}
        multiFilterSX={{ display: ['none', null, null, 'flex'] }}
        {...filterProps}
      />
      {isTakeoverOpen && bpIndex < 3 && (
        <FilterTakeover
          takeoverTitle={takeoverTitleOpenCta}
          isOpen={isTakeoverOpen}
          toggleOpen={toggleOpen}
          handleFilterSubmit={handleTakeoverFilterSubmit}
          filterProps={filterProps}
        />
      )}
    </React.Fragment>
  );
};

export default MultiFilterTemplate;
