/** @jsx jsx */
import { ReactElement, useMemo } from 'react';
import { graphql } from 'gatsby';
import HardcodedPagesLayout from '../HardcodedPagesLayout';
import { reduceObjValues } from '@sprinklr/shared-lib/utils';
import { jsx } from 'theme-ui';
import { useI18next } from 'gatsby-plugin-react-i18next';
import IntegrationsIndexHero from './components/IntegrationsIndexHero';
import IntegrationsIndexColumns from './components/IntegrationsIndexColumns';

export const IntegrationsIndexPageQuery = graphql`
  query (
    $nodeLocale: String!
    $isHeaderV3Enabled: Boolean!
    $translationLocale: String!
    $language: String!
    $pageName: String!
  ) {
    ...HeaderFooterSocialQuery
    ...MetaDataByPageName
    ...SiteMetadataQuery
    ...LocalesQuery
    ...AllIntegrationsCardQueryFragment
  }
`;

const PAGE_DATA = {
  productCategory: 'Platform',
};

const IntegrationsIndexPage = ({ data, pageContext }): ReactElement => {
  const {
    headerV3,
    footerV3,
    allContentfulGlobalHeaderFooter,
    allContentfulMetadata,
    integrationsCards,
    site,
  } = data;
  const metadata = reduceObjValues(allContentfulMetadata?.nodes[0], '', {
    exclude: ['twitterImage', 'openGraphImage'],
  });

  const { t } = useI18next();

  return (
    <HardcodedPagesLayout
      slug={'/products/platform/integrations'}
      context={pageContext}
      site={site}
      metadata={metadata}
      headerV3={headerV3}
      footerV3={footerV3}
      pageData={PAGE_DATA}
      globalHeaderFooterData={allContentfulGlobalHeaderFooter?.edges[0]?.node}
    >
      <IntegrationsIndexHero
        heroHeadline={t('Sprinklr Integrations')}
        heroCopy={t('integrationsHeroCopy')}
        ctaLabel={t('request demo')}
        ctaUrl={'/demo'}
      />
      <IntegrationsIndexColumns integrationsCards={integrationsCards?.nodes} />
    </HardcodedPagesLayout>
  );
};

export default IntegrationsIndexPage;
